import React from 'react'
import styled from 'styled-components';
import { css } from 'emotion';
import tw from 'tailwind.macro';
import Layout from '../components/Layout';
import NavBar from '../components/NavBar';
import { GlobalStyle } from '../pages/HomePage';
import Row from '../components/Row';
import { Waypoint } from 'react-waypoint';
import { Link, graphql } from 'gatsby';
import Image from '../components/Image';
import Menu from '../components/Menu';
import Prismic from 'prismic-javascript';
import Card from '../components/Card';
import AdCard from '../components/AdCard';
import Moment from 'moment';
import SEO from '../components/SEO'
import {Spring, animated, interpolate} from 'react-spring';
import { Transition } from 'react-spring/renderprops';
import ReactPlayer from 'react-player';
import StarRatingComponent from 'react-star-rating-component';
import Rating from 'react-rating';
import StarRatings from 'react-star-ratings'
import AdCardInDetailPage from '../components/AdCardInDetailPage'
import ShareIcons from '../components/ShareIcons'
import ReactGA from 'react-ga';
ReactGA.initialize('UA-141669883-1');

const apiEndpoint = 'https://prad.prismic.io/api/v2';



const SectionOne = styled.section`
    ${tw`flex flex-wrap relative mt-0 w-screen  bg-black items-start`};
    @media (min-width: 992px){
        min-height: 45rem;
    }
    height: auto;
`
const SectionTwo = styled.section`
    ${tw`relative h-auto py-8 bg-grey-lightest `};
`
const SectionThree = styled.section`
    ${tw`relative bg-grey-lightest py-12`};
     border-top: 1px solid rgba(150,150,175,0.2);
`
const Title = styled.h1`
    ${tw`font-black text-4xl lg:w-1/2 w-full`}
    color: #F2F4F7;
    letter-spacing: -0.5px;
`
const BannerImageWrapper = styled.div`
    ${tw`flex flex-wrap items-end w-screen relative overflow-hidden`};
    height: 30rem;
    ${props => `background-image: url(${props.image})`};
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
`
const Paragraph = styled.p`
    ${tw`my-8`}
    opacity: 0.77;
    font-family: HelveticaNeue;
    font-size: 18px;
    color: #2D2D2E;

    letter-spacing: 0.46px;
    line-height: ${1.618 / 0.809};
`

const BlackOVerlay = styled.div`
    ${tw`fixed z-999 h-screen w-screen overflow-hidden pin-t pin-x flex flex-wrap items-center`};
    background: rgba(0,0,0,0.85);
`
const AnimatedBlackOverlay = animated(BlackOVerlay);

class Article extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            feedInView: false,
            showMenu: false,
            invertNav: false,
            feedCategory: 'all',
            fixCategoryNav: false,
            showSearch: false,
            relatedArticles: [],
            showVideo: false,
            city: '',
            temp: '',
            searchResults: [],
            noResultsFound: false
        }
        this.sectionOneEnd = this.sectionOneEnd.bind(this);
        this.toggleShowMenu = this.toggleShowMenu.bind(this);
        this.onCloseClick = this.onCloseClick.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.showVideo = this.showVideo.bind(this);
        this.closeVideo = this.closeVideo.bind(this);
        // this.useGeolocation = this.useGeolocation.bind(this);
        // this.getWeatherInfo = this.getWeatherInfo.bind(this);
    }

    componentWillMount() {
        // this.useGeolocation();
    }

    sectionOneEnd() {
        // alert('Section One End');
        this.setState({ feedInView: true, invertNav: true });
    }

    toggleShowMenu(type) {
        let that = this;
        this.setState({
            showMenu: !this.state.showMenu,
            invertNav: !this.state.invertNav,
        },
            function () {
                //console.log('state Change: ' + JSON.stringify(this.state));
                if (type == 'search') {
                    //console.log('Search Clicked');
                    this.setState({ showSearch: true });
                }
                else {
                    //console.log('Menu Clicked');
                    this.setState({ showSearch: false });
                    return;
                }
            }
        )
    }

    onCloseClick() {
        this.setState({
            showMenu: !this.state.showMenu,
            invertNav: !this.state.invertNav,

        });
    }

    handleKeyPress(e, text) {
        let self = this;
        if (e.key === 'Enter') {
            Prismic.api(apiEndpoint).then(api => {
                //debugger
                api.query([
                    Prismic.Predicates.at('document.type', 'reviewArticle'),
                    Prismic.Predicates.fulltext('document', text)
                ]).then(function (response) {
                    //debugger
                    if(response.results.length == 0){
                        self.setState({ noResultsFound: true });
                    }
                    self.setState({ searchResults: response.results, noResultsFound: false });
                    
                    // response is the response object, response.results holds the documents
                });
            });
        }
    }

    showVideo(){
        this.setState({showVideo: true});
    }

    closeVideo(){
        this.setState({showVideo: false});
    }


    componentDidMount(){
        const ele = document.getElementById('ipl-progress-indicator')
        if(ele){
            ele.outerHTML = '';
        }
		
        let self = this;
        let tags = this.props.data.prismicReviewarticle.tags;
        let filteredTags = tags.filter((val, index, arr) => {
            return val != 'featured'
        });
        Prismic.api(apiEndpoint).then(api => {
            //debugger
            api.query(
                Prismic.Predicates.any('document.tags', filteredTags),
                { orderings: '[document.first_publication_date desc]' }
            ).then(function (response) {
                //console.log(response.results)
                let x = response.results.sort((a, b) => {return new Date(b.datetime) - new Date(a.datetime)})
                //console.log("SORTED");
                //console.log(x);
                self.setState({ relatedArticles: x }); 
                // response is the response object, response.results holds the documents
            });
        });
    }

    render() {
        let uidPath = this.props.data.prismicReviewarticle.uid;
        ReactGA.pageview(uidPath);
        ReactGA.event({
            category: 'Review',
            action: 'Card Click'
        });
        let article = this.props.data.prismicReviewarticle.data;
       // console.log(article)
        return (

            <>  
                {/* <SEO 
                    title={article.title.text}
                    lang = {"en"}
                    metaDescription={article.content.raw.text}
                    meta={[]}
                    keywords={[]}
                />  */}
                <GlobalStyle />
                <NavBar dark={this.state.feedInView || this.state.invertNav}
                    menuClick={() => this.toggleShowMenu('menu')}
                    onSearchClick={() => this.toggleShowMenu('search')}
                    onCloseClick={this.onCloseClick}
                    showClose={this.state.showMenu} />
                <Menu 
                    active={this.state.showMenu} 
                    showSearch={this.state.showSearch}
                    handleKeyPress={this.handleKeyPress}
                    searchResults={this.state.searchResults} 
                    noResultsFound = {this.state.noResultsFound}
                />
                <Layout>

                    <SectionOne>
                        <Row className={css`margin-top: 14rem;`}>
                            <div className={css`${tw`w-full mb-6 lg:px-8 px-4`}`}>
                                <Title>
                                    {article.title.text}
                                </Title>
                                {article.rating != null  && <StarRatingComponent
                                    name={'rating'}
                                    value={article.rating}
                                    // starCount={5}
                                    editing={false}
                                    // starColor={'yellow'}
                                    // emptyStarColor={'grey'}
                                    renderStarIcon={function(nextValue, prevValue, name){return <StarIcon active={nextValue <= prevValue}/>}}
                                    renderStarIconHalf={function (nextValue, prevValue, name) {return <HalfStarIcon />} }
                                />}
                            </div>
                        </Row>
                        <Waypoint onEnter={() => this.setState({ feedInView: false })} onLeave={() => this.setState({ feedInView: true })} scrollableAncestor={'window'} />
                        <BannerImageWrapper image={article.hasOwnProperty("img") && article.img.hasOwnProperty("url") ? article.img.url: ""}>
                            {/* <Image url = {article.img.url}/> */}

                            {article.embed.embed_url !== null  && 
                                <Row className={css`${tw`px-8`}`}>
                                    <div
                                        onClick={this.showVideo} 
                                        className={css`${tw`flex flex-no-wrap items-center w-full px-2 py-6 mb-4 rounded-lg lg:w-1/2 opacity-60 bg-black cursor-pointer hover:opacity-90`}`}>
                                        <img className={css`${tw`h-12 lg:h-16`}`} src={require('../assets/icons/playIcon.svg')} alt="Play Icon"/>
                                        <div className={css`${tw`ml-2 lg:ml-4`}`}>
                                            <h3 className={css`${tw`text-sm lg:text-normal text-white lg:w-2/3`}`}>{article.embed.title}</h3>
                                        </div>
                                    </div>
                                </Row>
                            }
                            {/* <h3>{article.embed.embed_url !== null ? 'Embed Url' : 'No Embed Url'}</h3> */}

                        </BannerImageWrapper>
                    </SectionOne>
                    <SectionTwo>
                        <Row>
                            <div className={css`${tw`lg:w-2/3 w-full py-4 px-4 lg:px-8
                            `}`}>
                                <ShareIcons path={uidPath}/>
                                {
                                    article.content.raw.map((para, i) => {
                                    return (
                                        <Paragraph key={i}>
                                            {para.text}
                                        </Paragraph>
                                    )
                                })}
                            </div>
                            <div style={{alignSelf:'normal',paddingTop:'50px',display:'flex'}}> 
                                <AdCardInDetailPage
                                    client='ca-pub-5630104718741028'
                                    slot='9368762673'
                                    style={{display: "block",height: 220, width: 340,marginLeft:'auto',marginRight:'auto' }}
                                    layout={"-6t+ed+2i-1n-4w"}
                                    format={"fluid"}
                                />
                            </div>
                        </Row>
                    </SectionTwo>

                    <SectionThree>
                        <Row justifyBetween className={css`${tw`lg:px-8`}`}>
                            <h3 className={css`${tw`w-full text-grey-dark`}`}>You may also like these</h3>
                            {this.state.relatedArticles.map((a, i) => {
                                if (a.uid != this.props.data.prismicReviewarticle.uid ) {
                                    if ((i + 1) % 4 == 0){
                                        return (
                                            <>
                                                <AdCard
                                                    client='ca-pub-5630104718741028'
                                                    slot='9368762673'
                                                    style={{ display: "block",height: 350, width: 350 }}
                                                    layout={"-6t+ed+2i-1n-4w"}
                                                    format={"fluid"}
                                                />
                                                <Card
                                                    key={i}
                                                    feed
                                                    related
                                                    title={a.data.title[0].text}
                                                    img={a.data.hasOwnProperty("img") && a.data.img.hasOwnProperty("url") ? a.data.img.url : ""}
                                                    date={Moment(a.data.datetime).format("DD MMMM, YYYY")}
                                                    time={Moment(a.data.datetime).format("h A")}
                                                    uid={a.uid}
                                                    category={a.data.categories ? a.data.categories[0].category.slug : ""}
                                                />
                                            </>
                                    )
                                    }
                                    else {
                                        return (
                                            <Card
                                                key={i}
                                                feed
                                                related
                                                title={a.data.title[0].text}
                                                img={a.data.hasOwnProperty("img") && a.data.img.hasOwnProperty("url") ? a.data.img.url : ""}
                                                date={Moment(a.data.datetime).format("DD MMMM, YYYY")}
                                                time={Moment(a.data.datetime).format("h A")}
                                                uid={a.uid}
                                                category={a.data.categories ? a.data.categories[0].category.slug : ""}
                                            />
                                        )
                                    }
                                }
                            }

                            )}
                            {/* <Card feed related/> */}
                        </Row>
                    </SectionThree>
                </Layout>


                <Transition 
                    native
                    from={{o:0}}
                    enter={{o: 1}}
                    leave={{o:0}}
                    items={this.state.showVideo}>

                    {items => items && 
                        (values => 
                            <AnimatedBlackOverlay 
                                style={{opacity: values.o.interpolate(o => o)}}>

                                <div className={css`${tw`relative flex flex-wrap items-center h-full w-full pin-t pin-x`}`}>
                                    <Row className={css`${tw`mt-8`}`}>
                                        {/* <div className={css`${tw`w-full`}`}> */}
                                            <div onClick={() => this.setState({ showVideo: false })} 
                                                 className={css`${tw`lg:w-auto bg-white opacity-40 text-black px-8 py-4 rounded-full cursor-pointer hover:opacity-100`}`}>
                                                Go Back
                                            </div>
                                        {/* </div> */}
                                        <div className={css`${tw`relative w-full lg:w-full py-8`}`}>

                                                <ReactPlayer url={article.embed.embed_url} 
                                                            playing
                                                            controls={true}
                                                            volume={0.05}
                                                            height={500}
                                                            width={'100%'}
                                                            light={true}
                                                            youtubeConfig={{ playerVars: { showinfo: 1, modestBranding: 1 } }}
                                                />
                                        </div>
                                    </Row>
                                </div>


                                {/* <Row>
                                    <div className={css`${tw`w-full flex flex-wrap items-center lg:justify-center lg:w-1/3`}`}>
                                        <AdCard responsive/>
                                    </div>
                                </Row> */}

                            </AnimatedBlackOverlay>
                        )
                    }

                </Transition>

               
            </>

        );
    }
}

export default Article;

export const pageQuery = graphql`
query ReviewArticleBySlug($uid: String!) {
   prismicReviewarticle(uid:{ eq: $uid }) {
      uid
      tags
      data {
        
        title {
          text
        }
        rating
        img{
            url
        }
        embed{
            embed_url
            thumbnail_url
            author_url
            title
        }
        content{
            raw{
                text
            }
        }
        categories{
            category{
              document{
                data{
                  name
                }
              }
            }
        }
      }
    }
}
`


const StarIcon =(props) => (

    <svg width="36" height="35" xmlns="http://www.w3.org/2000/svg">
        <path d="M18 28.5L6.832 34.371l2.133-12.435-9.035-8.807 12.486-1.815L18 0l5.584 11.314L36.07 13.13l-9.035 8.807 2.133 12.435z" 
                fill={props.active ? "#F1CA5E" : "#797C80"} 
                fillRule="evenodd"
                opacity={props.active ? 1: .5} />
    </svg>
);

const HalfStarIcon = () => (
    <svg width="36" height="35" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fill-rule="evenodd">
            <path fill="#797C80" opacity='.5' d="M18 28.5L6.832 34.371l2.133-12.435-9.035-8.807 12.486-1.815L18 0l5.584 11.314L36.07 13.13l-9.035 8.807 2.133 12.435z" />
            <path fill="#F1CA5E" d="M18.07 0v28.5L6.902 34.371l2.133-12.435L0 13.129l12.486-1.815L18.07 0z" />
        </g>
    </svg>
);